import React from "react";
import CustomerViews from "../components/CustomerViews/CustomerViews";
import Layout from "../components/Layout/Layout";
import HappyFaces from "../components/HappyFaces/HappyFaces";
import Seo from "../components/Seo";
import FreeDiscount from "../components/Discount/FreeDiscount";
import HeroSectionFree from "../components/HeroSection/HeroSectionFree";






const FreeFollowers = () => {





  return (
    <>
      <Seo
        title="Free Instagram Followers Trial"
        description="The Followers that Stagroo delivers are real and authentic, coming from legitimate IG users with real accounts on the app."
        canonical="/free-instagram-followers"
        keywords="free instaggram followers"
      />
      <Layout>
        <HeroSectionFree heading={"Free Instagram Followers Trial"} description={"The Followers that Stagroo delivers are real and authentic, coming from legitimate IG users with real accounts on the app."} />
        <FreeDiscount from="followers" />
        <HappyFaces />
        <CustomerViews />
      </Layout>
    </>
  );
};
export default FreeFollowers;