import axios from "axios";

const ServiceListData = async (myParam: string, currencyType: string) => {
  try {
    let Url;


    if (myParam === "likes") {
      Url = `${process.env.BACKEND_URL}/api/product/like_service?currencyType=${currencyType}`;
    } else if (myParam === "followers") {
      Url = `${process.env.BACKEND_URL}/api/product/follower_service?currencyType=${currencyType}`;
    } else if (myParam === "views") {
      Url = `${process.env.BACKEND_URL}/api/product/view_service?currencyType=${currencyType}`;
    } else {
      Url = `${process.env.BACKEND_URL}/api/product/like_service?currencyType=${currencyType}`;
    }

    const config = {
      method: "GET",
      url: Url,
    };
    const { data } = await axios(config);

    return data;
  } catch (error) {
    console.error(error);
  }
};

export default ServiceListData;
