import { Link, navigate } from "gatsby";
import React, { memo, useContext, useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { Colors } from "../../utils/colors";
import { UserContext } from "../Context";
import ServiceListData from "../serviceListData";
import Slider from "../Swiper/Slider";
import DiscountCard from "../DiscountCard/DiscountCard";
import { ClassNames } from "@emotion/react";
import { toast } from "react-toastify";

const Section = styled.section`
  
`;
const Card = styled.div`
background: #f9fdff;
  box-shadow: 0px 0px 4px rgba(122, 122, 122, 0.25);
  border-radius: 20px;
  @media (max-width: 575px) {
    padding-top: 18px;
  }
    border: 1px solid #eee;   
    padding: 2rem 10rem;
    margin-top: 7rem;
    margin-left: auto;
    margin-right: auto;
    max-width: 715px;
    align-items: center;
`

const Input = styled.input`
border-radius:5px;
outline:none;
height:45px;
`;
const InnerBtn = styled.div`
  width: 100%;
  background-color: ${Colors.primaryColor};
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.0125em;
  color: white;
  padding: 12px 24px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  span{
    text-transform: capitalize;
  }
  :hover {
    background: rgb(131 91 199);
  }
`;
const Name = styled.h4`
    color: #9ca0a5;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -.0205rem;
    margin-bottom: .5rem;
    text-transform: capitalize;
`
const Value = styled.h2`
    color: #313336;
    font-size: 28px;
    font-weight: 700;
    letter-spacing: -.04rem;
    display: inline-flex;
    align-items: flex-start;
    line-height: 1;
    gap: .8rem;
`
const Icon = styled.span`
svg{
  path{fill:rgb(29, 161, 242);}
}
`
const FreeTrial = styled.span`
color:rgb(29, 161, 242);
font-size:10px;
`

const Order = styled.span`
font-size: 12px;
color:#9ca0a5;
`

const FreeDiscount = ({ from }: any) => {

  let quantity = 50;
  if (from === "likes") {
    quantity = 50
  } else if (from === "views") {
    quantity = 500
  } else if (from === "followers") {
    quantity = 50
  }




  const {
    setPrice,
    posts,
    setPosts,
    setSelectCurrency,
    setBuyBtnPrice,
    selectCurrency,
    price,
  } = useContext(UserContext) as any;
  const [currency, setCurrency] = useState("$");
  const [pricing, setPricing] = useState(0);
  const [username, setUsername] = useState<string>("")
  useEffect(() => {
    setPricing(price);
  }, [price]);

  let myParam: string;
  if (typeof window !== "undefined") {
    const urlParams = new URLSearchParams(window.location.search);
    myParam = urlParams.get("service") as any;
  }
  const fetch = async () => {
    return await ServiceListData(myParam, currency);
  };

  useEffect(() => {
    fetch().then((res) => {
      setPosts(res);
    });
  }, [selectCurrency]);

  useEffect(() => {
    if (posts[0]?.price != undefined) {
      setPrice(posts[0].price);
    }
  }, [posts]);

  return (
    <Section className="container">
      <Card>
        <div className="d-block">
          <label className="pb-2" >Instagram Post URL</label>
          <Input
            placeholder="Instagram username"
            className="w-100 border border ps-2"
            onChange={(e) => setUsername(e.target.value)}
          />
          <div className="row py-4">
            <div className="col-6">
              <Name>{from} Amount :</Name>
              <Value>{quantity}</Value>
            </div>
            <div className="col-6">
              <Name>Price</Name>
              <div className="d-flex">
                <Name>$</Name>
                <Value>00.0</Value>
                <div className="free-trial d-flex">
                  <Icon className="px-1">
                    <svg className="svg-icon svg-icon--planCheck" width="12" height="12" viewBox="0 0 12 12" fill="rgb(29, 161, 242)" xmlns="http://www.w3.org/2000/svg"><path d="M5.27434 11.0391L0 6.96866L1.50311 5.20007L4.67896 7.65149L9.32546 0.561035L11.3512 1.76532L5.27434 11.0391Z" fill="#FF5629"></path></svg>
                  </Icon>
                  <FreeTrial>Free Trial</FreeTrial></div>
              </div>
            </div>
          </div>

          <div
            onClick={() => username.length === 0 ? toast.error("Please enter username"):navigate(`/service?id=${from}&username=${username}`)}
            style={{ cursor: "pointer" }}
          >
            <InnerBtn>
              <span className="me-1">Get Free {from}</span>

            </InnerBtn>
          </div>

          <div className="d-flex py-2 align-items-center">
            <Icon>
              <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg" ><path d="M13.1968 0.0639131C13.0491 2.68982 12.4583 4.54436 9.83239 6.67791C9.83239 7.79391 9.38927 8.90992 8.48662 9.69769L6.99313 8.02368C6.68131 8.31909 6.27101 8.4668 5.87713 8.48321C5.35194 8.49963 4.84318 8.30268 4.4657 7.89239C3.80923 7.15385 3.87487 6.02143 4.597 5.36495L3.10352 3.69094C4.00617 2.88676 5.155 2.55852 6.27101 2.68982C8.66715 0.310091 10.5709 -0.0837939 13.1968 0.0639131ZM10.4725 4.10124C10.9648 3.65812 10.9976 2.91958 10.5709 2.42723C10.3247 2.16463 9.99651 2.01693 9.63545 2.03334C9.35645 2.04975 9.09386 2.14822 8.89691 2.32875C8.40456 2.77188 8.37173 3.51041 8.79844 4.00277C9.04462 4.26536 9.37286 4.41306 9.73392 4.39665C9.99651 4.38024 10.2591 4.28177 10.4725 4.10124Z" fill="#FF5629"></path><path d="M0.873047 11.0105L0.955106 10.7479C0.971518 10.6822 1.43105 9.12309 2.02188 8.51585C2.35012 8.18761 2.744 8.00708 3.10506 8.00708C3.33483 8.00708 3.51536 8.07273 3.66307 8.22044C4.04054 8.59791 3.90925 9.32003 3.36765 9.86162C2.72759 10.5017 1.20128 10.912 1.13564 10.9448L0.873047 11.0105Z" fill="#FF5629"></path></svg>
            </Icon>
            <Order>Order starts immediately, free every 24 hours.</Order>
          </div>
        </div>

      </Card>


    </Section>
  );
};

export default FreeDiscount;
