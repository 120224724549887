import React from "react";
import styled from "styled-components";



const Wrapper = styled.div`
text-align:center;
padding-top:200px;
width:61%;
margin:0 auto;

`
const Header = styled.h1`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 58px;
  letter-spacing: 0.01em;
  color: #3d3d3d;
  @media (max-width: 1200px) {
    font-size: 42px;
  }
  @media (max-width: 991px) {
    font-size: 31px;
  }
  @media (max-width: 767px) {
    font-size: 39px;
    text-align: center;
  }
  @media (max-width: 575px) {
    font-size: 24px;
  }
`;

const Paragraph = styled.p`
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: 0.01em;
  padding: 16px 0px 40px 0px;
  color: #676767;
  @media (max-width: 991px) {
    font-size: 14px;
    line-height: 24px;
  }
  @media (max-width: 767px) {
    font-size: 14px;
    line-height: 24px;
  }
  @media (max-width: 575px) {
    padding: 8px 0px;
    text-align: center;
    margin-bottom: 26px !important;
  }
`;

const HeroSectionFree = ({ heading, description }: { heading: string; description: string }) => {
  return (<Wrapper >
    <Header>{heading}</Header>
    <Paragraph>{description}</Paragraph>
  </Wrapper>)
}

export default HeroSectionFree;